// .story-button {
// }

// .story-button::after {
//   content: "";
//   width: 10px;
//   height: 10px;
//   border-radius: 100%;
//   border: 6px solid #f6a71c;
//   position: absolute;
//   z-index: 1;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   animation: ring 1.5s infinite;
// }

// @keyframes ring {
//   0% {
//     width: 30px;
//     height: 30px;
//     opacity: 1;
//   }
//   100% {
//     width: 100px;
//     height: 100px;
//     opacity: 0;
//   }
// }

.img-button {
  background: #3e2e70;

  border-radius: 12px;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
}

.img-button img {
  display: block;

  border-radius: 12px;
  transform: translateY(-6px);

  -webkit-animation: mover 0.5s cubic-bezier(0.01, 0.88, 0.36, 1) 1s infinite
    alternate;

  animation: mover 0.5s cubic-bezier(0.01, 0.88, 0.36, 1) 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(-2px);
  }
  50% {
    transform: translateY(-6px);
  }

  100% {
    transform: translateY(-2px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(-2px);
  }
  50% {
    transform: translateY(-6px);
  }

  100% {
    transform: translateY(-2px);
  }
}
